export enum QuizStatus {
  notStarted = 'NOT STARTED',
  started = 'STARTED',
  finished = 'FINISHED',
}

export const QR_CODE_SRCIPT_URLS: string[] = [
  process.env.GATSBY_QR_CODE_WIDGET_SCRIPT_URL as string,
  process.env.GATSBY_QR_CODE_WIDGET_SCAN_SCRIPT_URL as string,
];

export const QR_CODE_VARIABLES_SETTINGS_CODE =
  process.env.GATSBY_QR_CODE_WIDGET_KEY &&
  process.env.GATSBY_QR_CODE_WIDGET_MAIN_API_URL &&
  // eslint-disable-next-line no-irregular-whitespace
  `var EVT_APPLICATION_API_KEY="${process.env.GATSBY_QR_CODE_WIDGET_KEY}"; var EVT_API_URL="${process.env.GATSBY_QR_CODE_WIDGET_MAIN_API_URL}";`;
