import React, { FC } from 'react';

import { Container } from 'layout';
import DangerouslySetInnerHtml from 'gatsby-theme-husky/src/layout/DangerouslySetInnerHtml';

import { IFindYourFitProps } from './models';
import './FindYourFitBanner.scss';
import './ShadowFindYourFitBanner.scss';

const FindYourFitBanner: FC<IFindYourFitProps> = ({ banner }) => (
  <div className="find-your-fit__banner">
    <Container className="find-your-fit__container">
      <DangerouslySetInnerHtml element="h1" html={banner[0].properties.title} />
      <DangerouslySetInnerHtml
        className="find-your-fit__banner__title"
        html={banner[0].properties.image.svg.content}
        alt={banner[0].properties.image.altText}
      />
    </Container>
  </div>
);

export default FindYourFitBanner;
