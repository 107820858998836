import { useState } from 'react';
import { ProductData } from '@shared/types/common/ProductData';
import {
  IProgressBarStep,
  IQuestionState,
  IQuizStateQuestion,
  IUmbracoProduct,
  IUmbracoQuestion,
  UpdateQuizDataStateFunc,
} from './model';

const defineSelectedProductsTagsIds = (products: IUmbracoProduct[]) => {
  const selectedProductsTagsIds: string[] = [];
  products.forEach((product: IUmbracoProduct) => {
    product.quizTag.forEach((quizTag: ProductData.ProductQuizTag) => {
      const quizTagId = quizTag.id.toString();
      if (selectedProductsTagsIds.indexOf(quizTagId) < 0) selectedProductsTagsIds.push(quizTagId);
    });
  });

  return selectedProductsTagsIds;
};

const defineInitialQuestionState = (questions: IUmbracoQuestion[]): IQuizStateQuestion[] => {
  return questions?.map((question, index) => {
    return {
      ...question,
      index,
    };
  });
};

const prepareBase64SVGData = (svgData: string) => {
  if (!svgData) return null;

  return `url('data:image/svg+xml;base64,${window.btoa(svgData)}')`;
};

const defineInitialAnswersState = (
  questions: IUmbracoQuestion[],
  products: IUmbracoProduct[]
): IQuestionState => {
  const questionState = {};
  questions.forEach((question, index) => {
    questionState[question.id] = {
      selectedOptions: [],
      preselectedProducts: index ? [] : products,
      allowedProductsTags: index ? [] : defineSelectedProductsTagsIds(products),
      isSingleSelected: false,
    };
  });

  return questionState;
};

const defineSelectedProducts = (tagsSelected: string[], allowedProductsList: IUmbracoProduct[]) => {
  if (!tagsSelected.length) return allowedProductsList;

  return allowedProductsList.filter((product: IUmbracoProduct) => {
    return product.quizTag.some((quizTag) => {
      return tagsSelected.indexOf(quizTag.id.toString()) !== -1;
    });
  });
};

const useQuiz = (questions: IUmbracoQuestion[], quizProducts: IUmbracoProduct[]) => {
  const quizQuestions = defineInitialQuestionState(questions);
  const [selectedProducts, setSelectedProducts] = useState(quizProducts);
  const [answersState, setAnswersState] = useState<IQuestionState>(
    defineInitialAnswersState(questions, quizProducts)
  );
  const [selectedQuestionNumber, setSelectedQuestion] = useState(0);
  const [presentedQuestion, setPresentedQuestion] = useState<IQuizStateQuestion>(
    quizQuestions[selectedQuestionNumber]
  );

  const numberOfSteps = questions.length;

  const changePresentedQuestion = (newQuestionIndex: number) => {
    window?.scroll(0, 0);
    setSelectedQuestion(newQuestionIndex);
    setPresentedQuestion(quizQuestions[newQuestionIndex]);
  };

  const clearCurrentQuestionOptions = () => {
    setAnswersState((prevState) => ({
      ...prevState,
      [presentedQuestion.id]: { ...prevState[presentedQuestion.id], selectedOptions: [] },
    }));
    setSelectedProducts([...answersState[presentedQuestion.id].preselectedProducts]);
  };

  const switchToNextQuestion = (skip?: boolean): void => {
    const nextQuestionId = quizQuestions[selectedQuestionNumber + 1].id;
    const selectedOptions = skip ? [] : [...answersState[presentedQuestion.id].selectedOptions];
    if (skip) clearCurrentQuestionOptions();
    const updSelectedProducts = selectedOptions.length
      ? defineSelectedProducts(
          selectedOptions,
          answersState[presentedQuestion.id].preselectedProducts
        )
      : [...answersState[presentedQuestion.id].preselectedProducts];
    setAnswersState((prevState) => ({
      ...prevState,
      [nextQuestionId]: {
        selectedOptions: selectedOptions.length
          ? [...prevState[nextQuestionId].selectedOptions]
          : [],
        preselectedProducts: updSelectedProducts,
        allowedProductsTags: defineSelectedProductsTagsIds(updSelectedProducts),
        isSingleSelected: selectedOptions.length
          ? prevState[nextQuestionId].isSingleSelected
          : false,
      },
    }));
    changePresentedQuestion(selectedQuestionNumber + 1);
  };

  const switchToPreviousQuestion = () => {
    changePresentedQuestion(selectedQuestionNumber - 1);
  };

  const showResults = (skip?: boolean) => {
    window?.scroll(0, 0);
    if (skip) {
      clearCurrentQuestionOptions();

      return answersState[presentedQuestion.id].preselectedProducts;
    }

    return selectedProducts;
  };

  const nextIsShown = presentedQuestion.index !== numberOfSteps - 1;

  const prevIsShown = presentedQuestion.index !== 0;

  const additionalQuizQuestionText = presentedQuestion.additionalQuestionInfo;

  const questionBackground = presentedQuestion.backgroundImage?.svg
    ? prepareBase64SVGData(presentedQuestion.backgroundImage.svg.content)
    : presentedQuestion.localBackgroundImage?.childImageSharp?.fluid?.base64
    ? `url(${presentedQuestion.localBackgroundImage?.childImageSharp?.fluid?.base64})`
    : null;

  const updateQuizDataState: UpdateQuizDataStateFunc = (
    selectedOptionId: string,
    isSelected: boolean,
    singleSelected: boolean = false
  ) => {
    let isSingleSelected = singleSelected;
    let updOptions: string[] = [];
    if (!answersState[presentedQuestion.id].isSingleSelected && !singleSelected) {
      updOptions = [...answersState[presentedQuestion.id].selectedOptions];
    } else if (singleSelected) {
      updOptions = [selectedOptionId];
    }
    const idIndex = updOptions.indexOf(selectedOptionId);
    if (isSelected && idIndex === -1) {
      updOptions.push(selectedOptionId);
    } else if (!isSelected && idIndex !== -1) {
      updOptions.splice(idIndex, 1);
      isSingleSelected = false;
    }
    const updSelectedProducts = defineSelectedProducts(
      updOptions,
      answersState[presentedQuestion.id].preselectedProducts
    );
    if (nextIsShown) {
      const nextQuestionId = quizQuestions[selectedQuestionNumber + 1].id;
      setAnswersState((prevState) => ({
        ...prevState,
        [presentedQuestion.id]: { ...prevState[presentedQuestion.id], selectedOptions: updOptions },
        [nextQuestionId]: {
          selectedOptions: [],
          preselectedProducts: updSelectedProducts,
          allowedProductsTags: defineSelectedProductsTagsIds(updSelectedProducts),
          isSingleSelected: false,
        },
      }));
    } else {
      setAnswersState((prevState) => ({
        ...prevState,
        [presentedQuestion.id]: {
          ...prevState[presentedQuestion.id],
          selectedOptions: updOptions,
          isSingleSelected,
        },
      }));
    }
    setSelectedProducts(updSelectedProducts);
  };

  const { allowedProductsTags, selectedOptions } = answersState[presentedQuestion.id];

  const nextStepIsDisabled = !presentedQuestion.skipButtonText && !selectedOptions.length;

  const steps: IProgressBarStep[] = quizQuestions?.map((question: IQuizStateQuestion) => {
    return {
      value: question.index + 1,
      key: `${question.index}-${question.id}`,
      isAnswered: selectedQuestionNumber < question.index,
    };
  });

  // Todo think about better and more elegant way. 30px is the width of the 'step-ball'
  const progressBarUnderlayWidth =
    selectedQuestionNumber + 1 < steps.length
      ? `calc(((100% - (40px * ${steps.length})) / (${(steps.length - 1) * 2}) * (${
          selectedQuestionNumber * 2 + 1
        })) + 40px * (${selectedQuestionNumber} + 1)`
      : '100%';

  return {
    numberOfSteps,
    presentedQuestion,
    switchToNextQuestion,
    switchToPreviousQuestion,
    showResults,
    nextIsShown,
    prevIsShown,
    nextStepIsDisabled,
    selectedOptions,
    allowedProductsTags,
    updateQuizDataState,
    steps,
    progressBarUnderlayWidth,
    additionalQuizQuestionText,
    questionBackground,
  };
};

export default useQuiz;
