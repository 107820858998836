import React from 'react';
import classNames from 'classnames';

import './QuizControllers.scss';
import { IQuizControllersProps } from './model';

const QuizControllers = (props: IQuizControllersProps) => {
  const {
    nextButtonText,
    nextButtonAriaLabel,
    prevButtonText,
    prevButtonAriaLabel,
    getResultsButtonText,
    getResultsButtonAriaLabel,
    prevIsShown,
    nextIsShown,
    nextStepIsDisabled,
    switchToNext,
    switchToPrevious,
    submitResults,
    backToMainPage,
  } = props;

  return (
    <div className="quiz-controllers">
      {nextIsShown ? (
        <button
          type="button"
          aria-label={nextButtonAriaLabel}
          className={classNames('button', 'button--transparent-white', {
            'next-disabled': nextStepIsDisabled,
          })}
          onClick={() => switchToNext()}
          disabled={nextStepIsDisabled}
        >
          {nextButtonText}
        </button>
      ) : (
        <button
          type="submit"
          aria-label={getResultsButtonAriaLabel}
          className="button button--transparent-white"
          onClick={submitResults ? () => submitResults() : undefined}
          disabled={nextStepIsDisabled}
        >
          {getResultsButtonText}
        </button>
      )}
      {prevIsShown ? (
        <button
          aria-label={prevButtonAriaLabel}
          type="button"
          className="prev-btn"
          onClick={() => switchToPrevious()}
        >
          {prevButtonText}
        </button>
      ) : (
        <button
          aria-label={prevButtonAriaLabel}
          type="button"
          className="prev-btn"
          onClick={() => backToMainPage()}
        >
          {prevButtonText}
        </button>
      )}
    </div>
  );
};

export default QuizControllers;
