import React from 'react';
import QuizResultItem from './QuizResultItem';
import './QuizResultsItemsContainer.scss';
import { IQuizResultsItemsContainerProps } from './model';

const QuizResultsItemsContainer = (props: IQuizResultsItemsContainerProps) => {
  const {
    header,
    products,
    readMoreButtonText,
    singleProductText,
    multipleProductsText,
    readMoreButtonAriaLabel,
    hideReadMoreButton,
  } = props;
  const productsQuantity = products.length;

  return (
    <div className="quiz-results">
      <div className="quiz-results__caption">
        <h2>{header}</h2>
        <span className="quiz-results__subtitle">
          {`${productsQuantity} ${
            productsQuantity === 1 ? singleProductText : multipleProductsText
          }`}
        </span>
      </div>
      <div className="quiz-results__list-holder">
        <ul className="quiz-results__list">
          {products?.map((product) => {
            const image = product.image[0];
            const quizTags = product.quizTag
              ? product.quizTag.filter((tag) => tag.icon)
              : undefined;

            return (
              <QuizResultItem
                title={product.title}
                image={image.localImage}
                amountCondoms={product.amountCondoms}
                imageAltText={image.properties.imageAltLabel}
                quizTags={quizTags}
                buttonText={readMoreButtonText}
                buttonAriaLabel={readMoreButtonAriaLabel}
                hideReadMoreButton={hideReadMoreButton}
                link={product.url}
                key={product.title}
              />
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default QuizResultsItemsContainer;
