import React from 'react';
import QuizQuestion from './QuizQuestion';
import QuizControllers from './QuizControllers';
import QuizProgressBar from './QuizProgressBar';

import './Quiz.scss';

import useQuiz from './useQuiz';

import { IUmbracoQuiz } from './model';

const Quiz = (props: IUmbracoQuiz) => {
  const {
    questions,
    nextButtonText,
    nextButtonAriaLabel,
    prevButtonText,
    prevButtonAriaLabel,
    getResultsButtonText,
    getResultsButtonAriaLabel,
    submitFunction,
    quizProducts,
    backToMainPage,
  } = props;

  const {
    presentedQuestion,
    switchToNextQuestion,
    switchToPreviousQuestion,
    showResults,
    nextIsShown,
    prevIsShown,
    nextStepIsDisabled,
    selectedOptions,
    allowedProductsTags,
    updateQuizDataState,
    steps,
    additionalQuizQuestionText,
    questionBackground,
  } = useQuiz(questions, quizProducts);

  const {
    subtitle,
    id: questionId,
    title: questionTitle,
    options,
    skipButtonText = '',
    skipButtonAriaLabel = '',
  } = presentedQuestion;

  const submit = (skip?: boolean) => {
    const selectedProducts = showResults(skip);
    submitFunction ? submitFunction(selectedProducts) : null;
  };

  const bgQuestionImg = questionBackground
    ? {
        backgroundImage: questionBackground,
      }
    : {};

  return (
    <div className="quiz-container">
      <div className="quiz-question__bg" style={bgQuestionImg} />
      <QuizProgressBar steps={steps} />
      <QuizQuestion
        options={options}
        id={questionId}
        subtitle={subtitle}
        title={questionTitle}
        selectedOptions={selectedOptions}
        selectedProductsTags={allowedProductsTags}
        skipButtonText={skipButtonText}
        skipButtonAriaLabel={skipButtonAriaLabel}
        updateQuizStatus={updateQuizDataState}
        skipStep={nextIsShown ? switchToNextQuestion : submit}
      />
      <QuizControllers
        nextButtonText={nextButtonText}
        nextButtonAriaLabel={nextButtonAriaLabel}
        prevButtonText={prevButtonText}
        prevButtonAriaLabel={prevButtonAriaLabel}
        getResultsButtonText={getResultsButtonText}
        getResultsButtonAriaLabel={getResultsButtonAriaLabel}
        prevIsShown={prevIsShown}
        nextIsShown={nextIsShown}
        switchToNext={switchToNextQuestion}
        switchToPrevious={switchToPreviousQuestion}
        nextStepIsDisabled={nextStepIsDisabled}
        submitResults={submit}
        backToMainPage={backToMainPage}
      />
      {additionalQuizQuestionText ? <p>{additionalQuizQuestionText}</p> : null}
    </div>
  );
};

export default Quiz;
