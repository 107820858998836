import React from 'react';
import GatsbyImage from 'gatsby-theme-husky/src/common/GatsbyImage';
import DangerouslySetInnerHtml from 'gatsby-theme-husky/src/layout/DangerouslySetInnerHtml';

import './QuizTagIcon.scss';
import { IQuizTagIconsProps } from './model';

const QuizTagIcon = (props: IQuizTagIconsProps) => {
  const { icon, image, title } = props;

  return (
    <li>
      {icon && icon.svg ? (
        <DangerouslySetInnerHtml
          className="quiz-tag-image"
          html={icon.svg.content}
          alt={title || ''}
        />
      ) : image?.childImageSharp ? (
        <GatsbyImage isLazyLoading={false} fluid={image.childImageSharp.fluid} alt={title || ''} />
      ) : null}
      <span>{title}</span>
    </li>
  );
};
export default QuizTagIcon;
