import { ProductData } from '@shared/types/common/ProductData';
import { IUmbracoProduct } from 'gatsby-theme-husky/src/components/Quiz/model';

export const createRelatedProductsList = (productsArray: IUmbracoProduct[]): IUmbracoProduct[] => {
  const relatedProducts: IUmbracoProduct[] = [];
  const relatedProductsSkus: string[] = [];
  productsArray.forEach((product) => {
    const productSku = product.sku;
    const existingProductIndex = relatedProductsSkus.indexOf(productSku);
    if (existingProductIndex !== -1) {
      relatedProductsSkus.splice(existingProductIndex, 1);
      relatedProducts.splice(existingProductIndex, 1);
    }
    product.relatedProducts?.forEach((relatedProduct) => {
      const { sku } = relatedProduct;
      if (relatedProductsSkus.indexOf(sku) === -1) {
        relatedProducts.push(relatedProduct);
        relatedProductsSkus.push(sku);
      }
    });
  });

  return relatedProducts;
};

export const addScript = (
  parent: HTMLElement,
  attribute: 'src' | 'innerHTML',
  value: string
): HTMLScriptElement | undefined => {
  const script = window?.document.createElement('script');
  if (!script) {
    return;
  }
  script[attribute] = value;
  parent.appendChild(script);

  return script;
};

export const makeQuizProducts = (quizProducts: ProductData.QuizProductContent[]) => {
  return quizProducts?.map((product) => {
    return {
      ...product.properties,
      url: product.url,
      relatedProducts: product.properties.quizRelatedProducts
        ? product.properties.quizRelatedProducts?.map((relatedProduct) => {
            return { ...relatedProduct.properties, url: relatedProduct.url };
          })
        : [],
    };
  });
};
