import React, { FC, useState, useEffect, useCallback, useMemo } from 'react';
import { graphql } from 'gatsby';
import classNames from 'classnames';

import { Layout } from 'layout';
import Quiz from 'components/Quiz/Quiz';
import QuizIntro from 'components/Quiz/QuizIntro';
import QuizResults from 'components/Quiz/QuizResults';
import {
  addScript,
  createRelatedProductsList,
  makeQuizProducts,
} from 'gatsby-theme-husky/src/templates/QuizPage/helper';
import FindYourFitBanner from 'components/FindYourFitBanner';
import { QR_CODE_SRCIPT_URLS, QR_CODE_VARIABLES_SETTINGS_CODE, QuizStatus } from './constants';
import { QuizPageComponentProps } from './models';
import { IUmbracoProduct } from '../../components/Quiz/model';

import 'templates/QuizPage/QuizPage.scss';

const QuizPage: FC<QuizPageComponentProps> = ({
  data: {
    umbracoQuiz: {
      quiz,
      additionalText,
      topBanner,
      topBannerDescription,
      seoMetaKeywords,
      seoMetaTitle,
      seoCanonicalUrl,
      seoMetaDescription,
      seoExternalHreflangs,
      defaultCompositions,
      quizImage: { localQuizImage },
      quizImageAltText,
      mobileQuizImage: { mobileLocalQuizImage },
      retakeQuizButtonText,
      retakeQuizButtonAriaLabel,
      quizProductsResults,
      relatedProductsResults,
      hideRelatedProducts,
      singleProductText,
      multipleProductsText,
      readMoreButtonText,
      readMoreButtonAriaLabel,
      hideReadMoreButton,
      homepageBottomImage1,
      homepageBottomImage2,
    },
    quizProducts: { nodes },
  },
}) => {
  const [quizStatus, setQuizStatus] = useState<QuizStatus>(QuizStatus.notStarted);
  const [selectedProducts, setSelectedProducts] = useState<IUmbracoProduct[]>([]);
  const [relatedProducts, setRelatedProducts] = useState<IUmbracoProduct[]>([]);
  const quizData = quiz[0];
  const startQuiz = useCallback(() => {
    window?.scroll(0, 0);
    setQuizStatus(QuizStatus.started);
  }, []);
  const backToMainPage = useCallback(() => {
    window?.scroll(0, 0);
    setQuizStatus(QuizStatus.notStarted);
  }, []);
  const retakeQuiz = useCallback(() => {
    startQuiz();
    setSelectedProducts([]);
    window?.scrollTo(0, 0);
  }, []);

  const quizProducts = useMemo(() => makeQuizProducts(nodes), [nodes]);

  const submit = useCallback((filteredProducts: IUmbracoProduct[]) => {
    setQuizStatus(QuizStatus.finished);
    setSelectedProducts(filteredProducts);
    setRelatedProducts(createRelatedProductsList(filteredProducts));
  }, []);

  const isQuizStarted = quizStatus === QuizStatus.started;
  const isQuizFinished = quizStatus === QuizStatus.finished;

  useEffect(() => {
    const isScriptParamsLoaded = QR_CODE_SRCIPT_URLS.every((script) => typeof script === 'string');
    let scriptSettings: HTMLScriptElement | undefined;
    let scripts: (HTMLScriptElement | undefined)[];

    if (isScriptParamsLoaded && typeof QR_CODE_VARIABLES_SETTINGS_CODE === 'string') {
      const headTag = window.document.getElementsByTagName('head')[0];
      scriptSettings = addScript(headTag, 'innerHTML', QR_CODE_VARIABLES_SETTINGS_CODE);
      scripts = QR_CODE_SRCIPT_URLS.map((url) => addScript(headTag, 'src', url));
    }

    return () => {
      scriptSettings && window.document.head.removeChild(scriptSettings);
      scripts?.forEach((script) => script && window.document.head.removeChild(script));
    };
  }, []);

  const quizState = (isQuizStarted && 'started') || (isQuizFinished && 'finished');

  const getQuiz = useCallback(() => {
    switch (quizState) {
      case 'started': {
        return (
          <Quiz
            prevButtonText={quizData.previousButtonText}
            prevButtonAriaLabel={quizData.previousButtonAriaLabel}
            nextButtonText={quizData.nextButtonText}
            nextButtonAriaLabel={quizData.nextButtonAriaLabel}
            getResultsButtonText={quizData.getResultsButtonText}
            getResultsButtonAriaLabel={quizData.getResultsButtonAriaLabel}
            questions={quizData.questions}
            submitFunction={submit}
            quizProducts={quizProducts}
            backToMainPage={backToMainPage}
          />
        );
      }
      case 'finished': {
        return (
          <QuizResults
            retakeQuiz={retakeQuiz}
            products={selectedProducts}
            relatedProducts={relatedProducts}
            buttonText={retakeQuizButtonText}
            buttonAriaLabel={retakeQuizButtonAriaLabel}
            quizProductsResults={quizProductsResults}
            relatedProductsResults={relatedProductsResults}
            singleProductText={singleProductText}
            multipleProductsText={multipleProductsText}
            readMoreButtonText={readMoreButtonText}
            readMoreButtonAriaLabel={readMoreButtonAriaLabel}
            hideRelatedProducts={hideRelatedProducts}
            hideReadMoreButton={hideReadMoreButton}
          />
        );
      }
      default: {
        return (
          <QuizIntro
            title={quizData.title}
            description={quizData.description}
            startQuiz={startQuiz}
            startQuizButtonText={quizData.startQuizButtonText}
            startQuizButtonAriaLabel={quizData.startQuizButtonAriaLabel}
            additionalText={additionalText}
            localQuizImage={localQuizImage}
            quizImageAltText={quizImageAltText}
            mobileLocalQuizImage={mobileLocalQuizImage}
            topBannerDescription={topBannerDescription}
            homepageBottomImage1={homepageBottomImage1}
            homepageBottomImage2={homepageBottomImage2}
          />
        );
      }
    }
  }, [quizState]);

  const topBannerVisible = topBanner?.length > 0;
  const quizWrapperClasses = classNames('quiz-page__wrapper', {
    'result-page': isQuizFinished,
    'raw-view': topBannerVisible,
  });

  return (
    <Layout
      defaultCompositions={defaultCompositions}
      seo={{
        seoMetaKeywords,
        seoMetaTitle,
        seoCanonicalUrl,
        seoMetaDescription,
        seoExternalHreflangs,
      }}
      className="quiz-page"
      hideFooter={topBannerVisible}
      hideHeader={topBannerVisible}
    >
      <div className={quizWrapperClasses}>
        {topBannerVisible ? <FindYourFitBanner banner={topBanner} /> : null}
        <div className="quiz">{getQuiz()}</div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query QuizPage($url: String = "", $lang: String = "") {
    umbracoQuiz: umbracoQuiz(url: { eq: $url }) {
      seoMetaTitle
      seoCanonicalUrl
      seoMetaDescription
      seoMetaKeywords
      seoExternalHreflangs {
        hreflang: key
        href: value
      }
      defaultCompositions {
        ...DefaultCompositionsFragment
      }
      additionalText
      quizImage {
        url
        fallbackUrl
        localQuizImage {
          childImageSharp {
            fluid(quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      quizImageAltText
      mobileQuizImage {
        url
        fallbackUrl
        mobileLocalQuizImage {
          childImageSharp {
            fluid(quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      quiz {
        id
        description
        questions {
          id
          title
          subtitle
          skipButtonText
          skipButtonAriaLabel
          additionalQuestionInfo
          backgroundImage {
            url
            svg {
              content
              absolutePath
              relativePath
            }
            fallbackUrl
          }
          localBackgroundImage {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          options {
            description
            id
            title
            imageAltText
            tag
            icon {
              url
              svg {
                content
                absolutePath
                relativePath
              }
              fallbackUrl
            }
            localOptionIcon {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            singleSelection
          }
        }
        getResultsButtonText
        getResultsButtonAriaLabel
        nextButtonText
        nextButtonAriaLabel
        previousButtonText
        previousButtonAriaLabel
        startQuizButtonText
        startQuizButtonAriaLabel
        title
      }
      topBanner {
        properties {
          image {
            svg {
              content
            }
            altText
          }
          title
        }
      }
      topBannerDescription
      relatedProductsResults
      hideRelatedProducts
      singleProductText
      multipleProductsText
      retakeQuizButtonText
      retakeQuizButtonAriaLabel
      quizProductsResults
      readMoreButtonText
      readMoreButtonAriaLabel
      hideReadMoreButton
      quizBreadcrumbsTitle
      quizHomepageText
      quizHomepageAriaLabel
      homepageBottomImage1 {
        altText
        gatsbyImage {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      homepageBottomImage2 {
        gatsbyImage {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        altText
      }
    }
    quizProducts: allUmbracoProducts(filter: { lang: { eq: $lang }, quizTagged: { eq: true } }) {
      nodes {
        url
        properties {
          title
          amountCondoms
          buttons {
            properties {
              buttonText
              buttonLink {
                url
              }
            }
          }
          image {
            properties {
              imageAltLabel
            }
            localImage {
              childImageSharp {
                fluid(maxWidth: 300) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
          quizTag {
            id
            name
            title
            icon {
              fallbackUrl
              svg {
                content
                absolutePath
                relativePath
              }
              url
              localQuizTagIcon {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
          sku
          quizRelatedProducts {
            url
            properties {
              title
              amountCondoms
              buttons {
                properties {
                  buttonText
                  buttonLink {
                    url
                  }
                }
              }
              image {
                properties {
                  imageAltLabel
                }
                localImage {
                  childImageSharp {
                    fluid(maxWidth: 300) {
                      ...GatsbyImageSharpFluid_noBase64
                    }
                  }
                }
              }
              quizTag {
                id
                name
                title
                icon {
                  fallbackUrl
                  svg {
                    content
                    absolutePath
                    relativePath
                  }
                  url
                  localQuizTagIcon {
                    childImageSharp {
                      fluid {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
              }
              sku
            }
          }
        }
      }
    }
  }
`;

export default QuizPage;
