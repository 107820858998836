import React from 'react';
import './QuizProgressBar.scss';
import { IProgressBarProps } from './model';

const QuizProgressBar = (props: IProgressBarProps) => {
  const { steps } = props;

  return (
    <div className="progress-bar">
      <ul className="progress-bar__steps">
        {steps?.map((step) => {
          return (
            <li key={step.key} className={step.isAnswered ? '' : 'active'}>
              <span className="circle">{step.value}</span>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default QuizProgressBar;
